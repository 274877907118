import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";

import Reveal from "stimulus-reveal-controller";
import Popover from "stimulus-popover";

const application = Application.start();
const context = require.context(".", true, /\.js$/);
application.load(definitionsFromContext(context));

application.register("reveal", Reveal);
application.register("popover", Popover);
